<template>
  <div>
    <!-- eslint-disable vue/attribute-hyphenation -->
    <vue-good-table
      ref="vgt"
      :columns="columns"
      :rows="data"
      :pagination-options="{
        enabled: true,
        perPage:filterProjects.limit
      }"
      styleClass="vgt-table"
    >
      <empty-state-table slot="emptystate" />
      <template
        slot="table-column"
        slot-scope="props"
      >
        <span v-if="props.column.label ==='id'">
          <span class="text-left">{{ props.column }}</span>
        </span>
      </template>
      <template
        slot="table-row"
        slot-scope="props"
      >
        <span v-if="props.column.field === 'name'">
          <router-link :to="{ name: 'project-detail', params: {id: props.row.id.toString()} }">
            <h6 class="text-primary m-0">{{ props.row.name }}</h6>
          </router-link>
          <b-badge
            v-if="props.row.category"
            variant="light-info"
            class="mt-50 mb-50"
          >
            {{ props.row.category.name }}
          </b-badge>
          <small
            v-if="props.row.tgl_reminder"
            class="d-block"
          >Tanggal reminder : {{ formatDate(props.row.tgl_reminder) }}</small>
        </span>
        <span v-else-if="props.column.field === 'status'">
          <b-badge
            :variant="props.row.status === 0 ? 'secondary' : 'primary'"
            class="mr-50"
          >
            {{ props.row.status === 0 ? 'Tidak Aktif' : 'Aktif' }}
          </b-badge>
        </span>
        <span v-else-if="props.column.field === 'lastQuote'">
          <div v-if="props.row.lastQuote.version">
            <div class="d-flex justify-content-between">
              <router-link :to="{ name: 'quotation-detail', params: {id: props.row.lastQuote.id.toString(), version: props.row.lastQuote.version.toString()} }">
                <h6 class="text-primary">{{ props.row.lastQuote.name }}</h6>
              </router-link>
              <b-badge :variant="statusOptions[props.row.lastQuote.status.id].badge">
                {{ statusOptions[props.row.lastQuote.status.id].label }}
              </b-badge>
            </div>
            <small
              v-if="props.row.lastQuote.closed_at && props.row.lastQuote.status === 6"
              class="d-block mt-25"
            >Tanggal ditutup : {{ formatDate(props.row.lastQuote.closed_at) }}</small>
            <small
              v-else-if="props.row.lastQuote.updated_at"
              class="d-block mt-25"
            >Tanggal diupdate : {{ formatDate(props.row.lastQuote.updated_at) }}</small>
            <small
              v-else
              class="d-block mt-25"
            >Tanggal dibuat : {{ formatDate(props.row.lastQuote.created_at) }}</small>
            <div class="d-flex justify-content-between align-items-center">
              <div>

                <small class="text-muted">Dibuat oleh</small>
                <b-media class="mt-25">
                  <template #aside>
                    <b-avatar
                      size="36"
                      :text="avatarText( props.row.lastQuote.userCreate.name )"
                      variant="light-primary"
                    />
                  </template>
                  <router-link :to="{ name: 'user-detail', params: {id: props.row.lastQuote.userCreate.id.toString()} }">
                    <h6 class="text-primary m-0">{{ props.row.lastQuote.userCreate.name }}</h6>
                  </router-link>
                  <small
                    v-if="props.row.lastQuote.userCreate.role"
                    class="d-block text-muted"
                  >
                    {{ props.row.lastQuote.userCreate.role.name }}
                  </small>
                </b-media>
              </div>
            </div>
          </div>
          <small
            v-else
            class="font-italic text-muted"
          >Belum Ada Quote</small>
        </span>
        <span v-else-if="props.column.field === 'total'">
          <h6 v-b-popover.hover.top="formatCurrency(props.row.lastQuote.total)">{{ shortNumber(props.row.lastQuote.total) }}</h6>
        </span>
        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <b-dropdown
              variant="link"
              toggle-class="text-decoration-none"
              no-caret
              offset="-100"
              boundary="window"
            >
              <template v-slot:button-content>
                <feather-icon
                  icon="MoreVerticalIcon"
                  size="16"
                  class="text-body align-middle mr-25"
                />
              </template>
              <b-dropdown-item
                :to="{ name: 'project-detail', params: {id: props.row.id.toString()} }"
              >
                <feather-icon
                  icon="EyeIcon"
                  class="mr-50 text-primary"
                />
                <span class="text-primary">Lihat Project</span>
              </b-dropdown-item>
              <b-dropdown-divider />
              <b-dropdown-item
                :disabled="!resolveUserPermission(props.row.isAssigned) || !permission.projectAdd"
                :to="{ name: 'addquote', params: { selectedProject: props.row, resetFormState: true } }"
              >
                <feather-icon
                  icon="FileTextIcon"
                  class="mr-50 mb-25"
                  :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'"
                />
                <span :class="props.row.isAssigned || $root.role === 2 ? 'text-info' : 'text-muted'">Buat Quote</span>
              </b-dropdown-item>
              <b-dropdown-item
                :disabled="!resolveUserPermission(props.row.isAssigned) || !permission.projectEdit"
                :to="{ name: 'project-edit', params: { id: props.row.id.toString() }}"
              >
                <div>
                  <feather-icon
                    icon="Edit2Icon"
                    class="mr-50"
                    :class="resolveUserPermission(props.row.isAssigned) || permission.projectEdit ? 'text-warning' : 'text-muted'"
                  />
                  <span :class="resolveUserPermission(props.row.isAssigned) || permission.projectEdit ? 'text-warning' : 'text-muted'">Edit</span>
                </div>
              </b-dropdown-item>
              <b-dropdown-item
                v-show="props.row.status === 1"
                :disabled=" !resolveUserPermission(props.row.isAssigned) || !permission.projectDelete "
                @click="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? confirmDelete(props.row.id) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="TrashIcon"
                  class="mr-50"
                  :class="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? 'text-danger' : 'text-muted'"
                />
                <span :class="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? 'text-danger' : 'text-muted'">Hapus Project</span>
              </b-dropdown-item>
              <b-dropdown-item
                v-show="props.row.status === 0 "
                :disabled="!resolveUserPermission(props.row.isAssigned) || !permission.projectDelete"
                @click="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? confirmRestore(props.row.id) : $router.push('/unauthorized')"
              >
                <feather-icon
                  icon="ShareIcon"
                  class="mr-50"
                  :class="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? 'text-danger' : 'text-muted'"
                />
                <span :class="resolveUserPermission(props.row.isAssigned) || permission.projectDelete ? 'text-danger' : 'text-muted'">Restore Project</span>
              </b-dropdown-item>
            </b-dropdown>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
        slot="pagination-bottom"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap">
              Lihat 1 hingga
            </span>
            <b-form-select
              v-model="filterProjects.limit"
              :options="limitSelection"
              class="mx-1"
            />
            <span class="text-nowrap "> dari total {{ count }} data </span>
          </div>
          <div>
            <b-pagination
              v-model="filterProjects.offset"
              :total-rows="count"
              :per-page="filterProjects.limit"
              first-number
              last-number
              align="right"
              prev-class="prev-item"
              next-class="next-item"
              class="mt-1 mb-0"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
  </div>
</template>

<script>
import {
  BFormSelect, BPagination, BDropdown, BDropdownItem, BDropdownDivider, BBadge, BMedia, BAvatar, VBPopover,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import moment from 'moment'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { avatarText, shortNumber, formatCurrency } from '@core/utils/filter'
import EmptyStateTable from '@/components/misc/EmptyStateTable.vue'
import { createHelpers } from 'vuex-map-fields'

const { mapFields } = createHelpers({
  getterType: 'getProjectsField',
  mutationType: 'updateProjectsField',
})

export default {
  components: {
    BFormSelect,
    BPagination,
    BDropdown,
    BDropdownItem,
    BDropdownDivider,
    BBadge,
    BMedia,
    BAvatar,
    VueGoodTable,
    EmptyStateTable,
  },
  directives: {
    'b-popover': VBPopover,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    count: {
      type: Number,
      default: 0,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      avatarText,
      shortNumber,
      formatCurrency,
      columns: [
        {
          field: 'name',
          label: 'Project Name',
          sortable: false,
          tdClass: 'w-vgt-280',
        },
        {
          field: 'status',
          label: 'Status',
          sortable: false,
        },
        {
          field: 'lastQuote',
          label: 'Last Quote',
          sortable: false,
          width: '300px',
        },
        {
          field: 'total',
          label: 'Total',
          sortable: false,
        },
        {
          label: 'Actions',
          field: 'action',
          sortable: false,
        },
      ],
    }
  },
  computed: {
    ...mapFields([
      'filterProjects',
      'limitSelection',
    ]),
    ...mapGetters({
      statusOptions: 'getStatusOptions',
    }),
    ...mapGetters({
      permission: 'getUserPermission',
    }),
  },
  mounted() {
    this.$refs.vgt.currentPage = this.filterProjects.offset
  },
  methods: {
    resolveUserPermission(isAssign) {
      return (this.$root.role === 2 || isAssign)
    },
    ...mapActions(['deleteProject', 'restoreProject']),
    formatDate(date) {
      if (!date) {
        return ''
      }
      const parsedDate = Date.parse(date.split('.')[0])
      return moment(parsedDate).format('DD-MM-YYYY')
    },
    confirmDelete(id) {
      this.$swal({
        title: 'Hapus Data Project?',
        text: 'Konfirmasi jika anda ingin menghapus data project',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.deleteProject(id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil menghapus data project',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })

            this.$emit('projectDeleted')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal menghapus data',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
    confirmRestore(id) {
      this.$swal({
        title: 'Restore Data Project?',
        text: 'Konfirmasi jika anda ingin restore data project',
        showCancelButton: true,
        confirmButtonText: 'Yakin',
        cancelButtonText: 'Batalkan',
        customClass: {
          confirmButton: 'btn btn-danger',
          cancelButton: ' text-danger btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.restoreProject(id).then(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Berhasil restore data project',
                icon: 'TrashIcon',
                variant: 'success',
              },
            })

            this.$emit('projectDeleted')
          }).catch(() => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Gagal restore data',
                icon: 'TrashIcon',
                variant: 'danger',
              },
            })
          })
        }
      })
    },
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
body.dark-layout .badge {
  color: #fff !important;
}

.badge-badgedb2 {
  background: #EF9F3D !important;
}
</style>
